import React, { Fragment, useEffect, useState } from "react";
import clsx from 'clsx'
import { Logo } from "../../components/shared-components/Logo";
import { Container } from "../../components/ui-components";
import {
  CategoriesIcon,
  ChatIcon,
  ForumIcon,
  HomeIcon,
  NotificationIcon,
} from "../../components/icons";
import { Link, useLocation } from "react-router-dom";
import HeaderSearch from "../../components/shared-components/GlobalSearch";
import UserMenu from "./UserMenu";
// import UserMenuLoggedOut from "./UserMenuLoggedOut";


const navigations = [
  {
    name: "Home",
    icon: HomeIcon,
    link:'/',
  },
  {
    name: "Categories",
    icon: CategoriesIcon,
    link:'/categories',
  },    
  {
    name: "Chat",
    icon: ChatIcon,
    link:'/chat',
  },
  {
    name: "Forum",
    icon: ForumIcon,
    link:'/forums',
  },
  {
    name: "Notification",
    icon: NotificationIcon,
    link:'/notification',
  }
];

const SiteHeader = () => {
  let location = useLocation();
  const [isHome, setIsHome] = useState(false);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    console.log("checkHome", location);
    if (location && location.pathname == "/") {
      setIsHome(true);
    }else{
      setIsHome(false);
    }
  }, [location]);

  return (
    <>
      {/* <div className="hidden md:flex md:gap-x-6">
    <Categories />
    </div>
    <Search /> */}

      <header
        className={`border-0 border-b border-b-gray-3 bg-header-background fixed top-0 left-0 right-0 w-full z-10`}>
        <Container style={{ width: "100%", maxWidth: "100%" }}>
          <nav className="relative z-50 flex justify-between gap-5">
            <div className="flex justify-start items-center gap-5 w-full">
              <a href="#" aria-label="Home">
                <Logo className="h-10 w-auto" />
              </a>
              {isHome ? null : <HeaderSearch />}
            </div>
            <div className="flex justify-between items-center gap-5 w-full">
              <div
                className={`flex items-start w-full justify-end`}>
                <div className="flex flex-row justify-between items-baseline gap-0">
                  {
                    navigations.map((navItem)=>(
                      <Link to={navItem.link} className={`flex relative flex-col justify-center min-h-16 items-center w-20  font-medium text-center text-xs text-dark hover:opacity-100 ${location.pathname == navItem.link?'after:w-full after:absolute after:-bottom-1 after:h-1 after:border-b-2 after:border-b-dark text-dark opacity-100':'opacity-55'}`}>
                        {/* {navItem.icon} */}
                        <navItem.icon
                          // className={clsx(location.pathname == navItem.link ? 'opacity-100' : 'opacity-50 hover:opacity-100')}
                          />
                        <span>
                          {navItem.name}
                        </span>
                      </Link>
                    ))
                  }
                </div>
              </div>
              <div className="flex items-center justify-end">
                <UserMenu HomePage={isHome} />
              </div>
            </div>
          </nav>
        </Container>
      </header>

      {/* {isLoggedIn ? <UserMenu /> : <UserMenuLoggedOut isLoggedIn={isLoggedIn} />} */}
      
    </>
  );
};

export default SiteHeader;
