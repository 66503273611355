import React from "react";

const AboutCourse = () => {
  return (
    <section className="p-6 w-full">
      <h3 className="text-xl font-bold mb-4">About Course</h3>
      <p className="text-text text-sm">
        User Experience (UX) Design is a multidisciplinary field focused on the interaction between users and digital or a ther they physical products. The goal of UX design is to create products that provide meaningful and relevant experiencel to a users. This involves the design of the entire process of acquiring and integrating the product, including aspect of arel branding, design, usability, and function.
      </p>
      <p className="text-text text-sm">
        The goal of UX design is to create products that provide meaningful and relevant experiences to users. This invol they design of the entire process of acquiring and integrating the product, including aspect of branding, designin usability and function.
      </p>
    </section>
  );
};

export default AboutCourse;
