import React from "react";

const CourseLeftPart = () => {
  return (
    <div className="bg-white p-1 rounded-lg shadow-md">
      <div className="aspect-w-16 aspect-h-9 bg-gray-200 rounded-lg mb-4"></div>
      <div className="flex space-x-4 mb-6">
        <button className="px-4 py-2 bg-gray-800 text-white rounded-md">Group Course</button>
        <button className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md">One on One</button>
      </div>

      {/* Batch Selection */}
      <div>
        <h2 className="text-lg font-semibold mb-4">3 Batches Available</h2>
        <div className="space-y-4">
          {/* Batch 1 */}
          <div className="bg-white p-4 rounded-lg shadow-sm flex items-start">
            <input
              type="radio"
              name="batch"
              className="mt-1 mr-4 cursor-pointer"
            />
            <div className="flex-1">
              <div className="flex justify-between">
                <div>
                  <span className="bg-gray-200 text-xs px-2 py-1 rounded-md">ONLINE</span>
                  <h3 className="text-lg font-medium mt-2">Batch 1</h3>
                  <p className="text-sm text-gray-600">May 24 - Aug 24</p>
                </div>
                <div className="text-right">
                  <p className="text-lg font-semibold">₹ 6,000 <span className="text-green-600 text-sm">40% Off</span></p>
                  <p className="text-sm text-gray-600">Installment Available</p>
                </div>
              </div>
              <p className="text-sm text-gray-600 mt-2">24 Enrolled | 2 seats left</p>
              <a href="#" className="text-blue-500 text-sm underline">View full schedules</a>
            </div>
          </div>

          {/* Batch 2 */}
          <div className="bg-white p-4 rounded-lg shadow-sm flex items-start">
            <input
              type="radio"
              name="batch"
              className="mt-1 mr-4 cursor-pointer"
            />
            <div className="flex-1">
              <div className="flex justify-between">
                <div>
                  <span className="bg-gray-200 text-xs px-2 py-1 rounded-md">CLASSROOM</span>
                  <h3 className="text-lg font-medium mt-2">Batch 2</h3>
                  <p className="text-sm text-gray-600">May 24 - Aug 24</p>
                </div>
                <div className="text-right">
                  <p className="text-lg font-semibold">₹ 7,000 <span className="text-green-600 text-sm">30% Off</span></p>
                  <p className="text-sm text-gray-600">Installment Available</p>
                </div>
              </div>
              <p className="text-sm text-gray-600 mt-2">24 Enrolled | 2 seats left</p>
              <p className="text-sm text-gray-600">Koramangala, Bangalore</p>
              <a href="#" className="text-blue-500 text-sm underline">View full schedules</a>
            </div>
          </div>

          {/* Batch 3 */}
          <div className="bg-white p-4 rounded-lg shadow-sm flex items-start">
            <input
              type="radio"
              name="batch"
              className="mt-1 mr-4 cursor-pointer"
            />
            <div className="flex-1">
              <div className="flex justify-between">
                <div>
                  <span className="bg-gray-200 text-xs px-2 py-1 rounded-md">ONLINE</span>
                  <h3 className="text-lg font-medium mt-2">Batch 3</h3>
                  <p className="text-sm text-gray-600">May 24 - Aug 24</p>
                </div>
                <div className="text-right">
                  <p className="text-lg font-semibold">₹ 4,500 <span className="text-green-600 text-sm">20% Off</span></p>
                  <p className="text-sm text-gray-600">Installment Available</p>
                </div>
              </div>
              <p className="text-sm text-gray-600 mt-2">24 Enrolled | 2 seats left</p>
              <a href="#" className="text-blue-500 text-sm underline">View full schedules</a>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <div className="mt-6">
        <button className="w-full bg-black text-white py-3 rounded-md text-lg font-semibold">ENROLL</button>
        <div className="mt-4 flex justify-between text-sm text-gray-600">
          <p>✓ Flexible Installments <span className="text-blue-500 underline">Know more</span></p>
          <p>🔒 Secured Transaction</p>
        </div>
      </div>

      {/* Question Section */}
      <div className="mt-6 bg-white p-4 rounded-lg shadow-md">
        <h3 className="text-lg font-medium mb-2">Have a Question?</h3>
        <textarea
          placeholder="Send your questions to the instructor"
          className="w-full p-3 border rounded-md text-sm focus:outline-none focus:ring-2 focus:ring-gray-300"
        ></textarea>
        <button className="w-full mt-2 bg-blue-600 text-white py-2 rounded-md">Send</button>
      </div>
    </div>
  );
};

export default CourseLeftPart;
