import React, { useState } from 'react'
import rightArrow from "../../../assets/svgs/rightArrow.svg";

function DeleteAC() {
    const [open,setOpen] = useState(false);
             
  return (
    <>
        <div
        className="w-full flex flex-row justify-between items-start transition-transform duration-300 ease-in-out hover:scale-105 hover:cursor-pointer mt-8"
        onClick={() => setOpen(true)}>
        <div className="w-full flex flex-row justify-between gap-2">
          <p className="text-base text-primary">Delete Account</p>
          <img src={rightArrow} alt="" />
        </div>
      </div>
    </>
  )
}

export default DeleteAC;