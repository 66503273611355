
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import SiteHeader from './header';
import SiteFooter from './footer';

const ThemeLayout = () => {
  const [fetching, setFetching] = useState(true);

  return (
    <div className="bg-white relative pt-14">
      <SiteHeader />
      <main>
        <Outlet />
      </main>  
      <SiteFooter />
    </div>
  );
};

export default ThemeLayout;
