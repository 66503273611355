import { Fragment, useState } from 'react'
import { useLoaderData } from "react-router-dom";
import CourseTopSection from "./CourseTopSection";

import AboutCourse from "./AboutCourse";
import Curriculum from "./Curriculum";
import { Container } from '../../components/ui-components';
import CourseLeftPart from './CourseLeftPart';
import CourseInfo from './CourseInfo';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

export async function courseDetailLoader(){
  return {
    date: new Date().toISOString(),
  };
}


export default function CourseDetail() {
 
  let data = useLoaderData();
  return (
    <div className='bg-white relative'>
      <section className='bg-gray-3'>
        <Container>
          <div className='flex flex-row'>
            <div className='flex flex-1'>
              <CourseInfo />
            </div>
            <div className='flex w-96 relative'>
              <div className='absolute top-9'>
                <CourseLeftPart />
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className=''>
        <Container>
          <div className='flex flex-row'>
            <div className='flex flex-1'>
              <AboutCourse />
            </div>
            <div className='flex w-96 relative'>
            </div>
          </div>
        </Container>
      </section>
    </div>   
  );
}